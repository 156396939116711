// For handling the navbar functionality on mobile screens for showing and hiding the menu
const hamburger = document.querySelector(".hamburger");
const closeicon = document.querySelector(".closeicon");
const mobilenav = document.querySelector(".mobilenav");

const toggleNav = () => {
    if (mobilenav.classList.contains("show")) {
        // Add delay before completely hiding the menu
        setTimeout(() => {
            mobilenav.style.visibility = "hidden";
        }, 300); // Match the CSS transition duration
    } else {
        mobilenav.style.visibility = "visible";
    }
    mobilenav.classList.toggle("show");
};

hamburger.addEventListener("click", toggleNav);
closeicon.addEventListener("click", toggleNav);




// For handling the sliders for popular dishes and green twist initiative
// Function to duplicate marquee content to create seamless scrolling
function duplicateMarquee(marqueeId) {
    const marquee = document.getElementById(marqueeId);
    const clone = marquee.innerHTML; // Clone the original content
    marquee.innerHTML += clone; // Append it to create a seamless effect
}
// Apply duplicate content to both marquees
duplicateMarquee("marquee-1");
duplicateMarquee("marquee-2");
duplicateMarquee("marquee-3");
duplicateMarquee("marquee-4");

